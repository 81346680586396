export default class Produto {
  constructor(id = "", nome = "", descricao = "", codMarca = "", codLinha = "", precoVenda = "", referencia = "", comissaoPersonalizada = 0, semComissao = 0) {
    this.id = id
    this.nomeProduto = nome
    this.descricao = descricao
    this.precoVendaString = precoVenda
    this.codMarca = codMarca
    this.codLinha = codLinha
    this.referencia = referencia
    this.comissaoPersonalizada = comissaoPersonalizada
    this.semComissao = semComissao
  }
}
