<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Nome"
            name="nome"
            placeholder="Nome"
            v-model="produto.nomeProduto"
            :counter="80"
            :rules="nameRules"
            required
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Descrição"
            name="Descricao"
            placeholder="Descrição"
            v-model="produto.descricao"
            :counter="80"
            required
          ></v-text-field>
        </div>
        <div class="d-flex">
          <select-marca class="mr-2"></select-marca>
          <select-linha class="mr-2"></select-linha>
        </div>
        <div class="d-flex">
          <v-text-field
            type="number"
            class="mr-2"
            label="Comissão personalizada"
            name="comissaoPersonalizada"
            placeholder="Comissao personalizada"
            v-model="produto.comissaoPersonalizada"
            :counter="3"
            required
          ></v-text-field>
          <v-switch v-model="semComissao" :label="`Produto Sem Comissão`"></v-switch>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Referência"
            name="Referencia"
            placeholder="Referência"
            v-model="produto.referencia"
            :counter="5"
            :rules="referenciaRules"
            :readonly="view"
            @blur="verificaReferenciaDisponivel"
            :error-messages="referenciaValida == 'error_outline' ? 'Referência já utilizada' : ''"
            :append-icon="referenciaValida"
            required
          ></v-text-field>
          <money class="mr-2" v-model="produto.precoVendaString" v-bind="money"></money>
        </div>
        <div class="form-btn">
          <v-btn outline @click="voltar">Voltar</v-btn>
          <v-btn outline @click="clear">Limpar</v-btn>
          <v-btn outline @click="submit" color="primary">Salvar</v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import { VMoney, Money } from "v-money"
import { ServicoProduto } from "../../../servicos/servicoProduto.js"
import Produto from "../../../domain/produto/Produto"
import SelectMarca from "../../marca/selectMarca"
import SelectLinha from "../../linha/selectLinha"
const servicoProduto = new ServicoProduto()
export default {
  components: {
    SelectMarca,
    SelectLinha,
    Money
  },
  data: () => ({
    id: null,
    view: false,
    produto: new Produto(),
    referenciaValida: "",
    semComissao: false,
    nameRules: [
      v => !!v || "Nome é obrigatório",
      v => v.length <= 80 || "Nome não deve possuir mais que 80 caracteres",
      v => v.length >= 3 || "Nome não deve possuir menos que 3 caracteres"
    ],
    referenciaRules: [
      v => !!v || "Referência é obrigatória",
      v => v.length <= 5 || "Referência não deve possuir mais que 5 caracteres"
    ],
    valid: true,
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: " # Preço de venda",
      precision: 2,
      masked: false
    }
  }),
  directives: { money: VMoney },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoProduto.buscarPorId(this.id).then(res => {
        this.view = true
        this.produto = res.data
        this.$children[0].$children[2].codMarca = this.produto.codMarca
        this.$children[0].$children[3].codLinha = this.produto.codLinha
      })
    }
  },
  methods: {
    verificaReferenciaDisponivel() {
      if (this.produto.referencia != "" && !this.id) {
        servicoProduto.referenciaDisponivel(this.produto.referencia).then(res => {
          if (res.data) {
            this.$children[0].$children[4].valid = true
            this.referenciaValida = "done_outline"
          } else {
            this.$children[0].$children[4].valid = false
            this.referenciaValida = "error_outline"
          }
        })
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.produto.id = this.id ? this.id : 0
        this.produto.codMarca = this.$children[0].$children[2].codMarca
        this.produto.codLinha = this.$children[0].$children[3].codLinha
        this.produto.comissaoPersonalizada = parseFloat(this.produto.comissaoPersonalizada)
        this.produto.semComissao = parseInt(this.semComissao ? 1 : 0)
        servicoProduto.salvarProduto(this.produto).then(
          res => {
            if (res.status === 200) {
              this.voltar()
            }
          },
          // eslint-disable-next-line
          err => console.log(err)
        )
      }
    },
    clear() {
      this.produto = new Produto()
      this.referenciaValida = "error_outline"
      this.$refs.form.resetValidation()
    },
    voltar() {
      this.$router.push({ name: "Produto" })
    }
  }
}
</script>
<style>
a.nounderline:link {
  text-decoration: none;
}
</style>
