<template>
  <v-select
    autocomplete
    label="Linha"
    placeholder="Selecione..."
    v-model="codLinha"
    :items="linhas"
    item-value="id"
    item-text="nome"
    required
  ></v-select>
</template>
<script>
import { ServicoLinha } from "../../servicos/servicoLinha"
const servicoLinha = new ServicoLinha()
export default {
  data: () => ({
    linhas: [],
    codLinha: ""
  }),
  created() {
    servicoLinha.buscarLinhas().then(
      res => {
        if (res.status === 200) {
          this.linhas = res.data
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  }
}
</script>
