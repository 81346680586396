<template>
  <v-select
    autocomplete
    label="Marca"
    placeholder="Selecione..."
    v-model="codMarca"
    :items="marcas"
    item-value="id"
    item-text="nome"
    required
  ></v-select>
</template>
<script>
import { ServicoMarca } from "../../servicos/servicoMarca"
const servicoMarca = new ServicoMarca()
export default {
  data: () => ({
    marcas: [],
    codMarca: ""
  }),
  created() {
    servicoMarca.buscarMarcas().then(
      res => {
        if (res.status === 200) {
          this.marcas = res.data
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  }
}
</script>
